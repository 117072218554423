<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">{{ title }}</h1>
      <h3 class="container ls-page-header-subtitle">
        With Our Merchants, you can choose how you would like to receive your
        products <br />
      </h3>
    </div>

    <div class="ps-section__content ls-page-content"></div>
  </div>
</template>

<script>
export default {
  name: "PickupDelivery",
  data() {
    return {
      title: "Pickup/Delivery",
    };
  },
};
</script>
